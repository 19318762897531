customElements.define(
    "pi-ll",
    class extends HTMLElement {
        constructor() {
            super();

            let tpl = document.createElement("template");
            tpl.innerHTML = `
                <button ord="tertiary" size="icon">
                    <rm-icon name="close"></rm-icon>
                </button>
            `;

            this.append(tpl.content.cloneNode(true));

            this.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
            });
        }

        connectedCallback() {
            this.addEventListener("click", (event) => {
                let pillEvent = null;
                if (event.target.closest("button")) {
                    pillEvent = this.getAttribute("onclose");
                }

                if (pillEvent) {
                    document.body.dispatchEvent(new CustomEvent(pillEvent));
                }
            });
        }
    },
);
