export function createEventCapture() {
    let events = [];

    document.addEventListener("visibilitychange", function logData() {
        if (document.visibilityState === "hidden" && events.length) {
            navigator.sendBeacon("/events", JSON.stringify(events));
            events = [];
        }
    });

    return {
        capture: (event) => {
            events.push({
                ...event,
                datetime_captured: new Date().toISOString(),
                path: window.location.pathname,
                time: performance.now(),
            });
        },
    };
}
